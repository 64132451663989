import request from '@/utils/request'

const competitorApi = {
    update: '/api/competitor',
    list: '/api/competitors',
    get: '/api/competitor/',
    deletecompetitor: '/api/competitor/'
}

export function removecompetitor(id){
    return request({
        url: competitorApi.deletecompetitor + id,
        method: 'delete'
    });
}

export function addorupdate (parameter) {
    return request({
        url: competitorApi.update,
        method: 'post',
        data: parameter
    })
}

export function list() {
    return request({
        url: competitorApi.list,
        method: 'get'
    });
}

export function getitem(id) {
    return request({
        url: competitorApi.get + id,
        method: 'get'
    });
}